import React from 'react';
import { connect } from 'react-redux';
import {hideModal} from '../actions';
import PropTypes from "prop-types";
import ReactBSAlert from "react-bootstrap-sweetalert";
import languages from "../languages";
import QRCode from "qrcode.react";

const ShareYekpayLinkDialog = ({name, link, hideModal, lang}) => {
    return (
        <ReactBSAlert
            status={'info'}
            title={name}
            style={{ display: "block", marginTop: "-250px", width: "600px" }}
            onConfirm={hideModal}
            onCancel={hideModal}
            confirmBtnBsStyle={'info'}
            confirmBtnText={languages[lang].confirm}
        >
            <QRCode value={link} renderAs='svg' level='H' size={200}/>
            <br />
            <br />
            <a href={link}>{link}</a>
        </ReactBSAlert>
    );
};

ShareYekpayLinkDialog.propTypes = {
    name: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    lang: PropTypes.string
};

export default connect(null, { hideModal })(ShareYekpayLinkDialog);

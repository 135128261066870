export const INIT_USER = 'INIT_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const DATA = 'DATA';
export const TICKET_COUNT = 'TICKET_COUNT';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const initUser = (user) => {
    return{
        type: INIT_USER,
        user
    }
};

export const updateUser = (user) => {
    return{
        type: INIT_USER,
        user
    }
};

export const logoutUser = () => {
    return {
        type: LOGOUT_USER
    }
};

export const setData = (data) => ({
    type: DATA,
    data
});

export const setCountTicket = (count = 0) => ({
    type: TICKET_COUNT,
    count
});

export const showModal = (modal, props = undefined) => ({
    type: SHOW_MODAL,
    modal,
    props
});

export const hideModal = () => ({
    type: HIDE_MODAL
});

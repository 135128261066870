/**
 * Created by morteza on 5/14/17.
 */
import { combineReducers } from 'redux'
import userReducer from './userReducer';
import dataReducer from "./dataReducer";
import ticketCountReducer from "./ticketCountReducer";
import dialogReducer from "./dialogReducer";

const reducerApp = combineReducers({
    userReducer,
    dataReducer,
    ticketCountReducer,
    dialogReducer
});

const initialState = reducerApp({}, {});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_USER') {
    state = undefined;
  }
  return reducerApp(state, action)
};

export default rootReducer


import React from "react";
import languages from "../../languages";
// reactstrap components
import {
    Row,
    Col, Button
} from "reactstrap";
import RequestServer from "../../RequestServer";
import Loading from "../components/Loading";
import {getLang, setLink} from "../../Values";
import {connect} from "react-redux";
import {showModal} from "../../actions";
import {Link} from "react-router-dom";
import {
    EmailIcon,
    EmailShareButton,
    LinkedinIcon,
    LinkedinShareButton, TelegramIcon, TelegramShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";

class Callback extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            detail: undefined,
        };
    }

    componentDidMount() {
        this.request();
    }

    request = () => {
        RequestServer('post', 'boot', response => {
            this.makeRemoteRequest(response);
        }, {
            post: {secret: "AppYek**175GHD@#"},
            lang: this.lang,
            onAnyFailed: () => {
                this.setState({loading: false});
            }
        });
    };

    makeRemoteRequest = (bootData) => {
        const {params} = this.props.match;
        RequestServer('post', "transaction/receipt", (response) => {
            response['currency'] = bootData.currencies.find(item => item.id === response.currency_id);
            response['event'] = bootData.events.find(item => item.id === response.event_id);
            this.setState({detail: response, loading: false});
        }, {
            post: {signature: params.signature, authority: params.authority},
            lang: this.lang,
            thisProps: {data: bootData, showModal: this.props.showModal},
            onAnyFailed: () => {
                this.setState({loading: false});
            }
        });
    };

    render() {
        this.lang = getLang(this.props);
        const {loading, detail} = this.state;

        if (loading || !detail)
            return (<Loading lang={this.lang}/>);
        else {
            const receiptClass = `receipt-container${detail.success === 1 ? '' : '-failed'}`;
            return (
                <>
                    <Row className="justify-content-center mt-5">
                        <Col lg="6" xs="10">
                            <div className={receiptClass}>
                                <h3>{languages[this.lang].transactionReceipt}</h3>
                                <div className="receipt-footer"/>
                                <Col className="mx-auto">
                                    <Row
                                        className="mb-2">{languages[this.lang].transactionStatus}: {detail.success === 1 ?
                                        <span style={{color: '#39b649'}}> {languages[this.lang].successful} </span> :
                                        <span style={{color: 'red'}}> {languages[this.lang].unSuccessful} </span>}</Row>
                                    <Row
                                        className="mb-2">{languages[this.lang].trackingCode}: {detail.tracking_code}</Row>
                                    <Row
                                        className="mb-2">{languages[this.lang].amount}: {detail.amount} {detail.currency.symbol}</Row>
                                    <Row
                                        className="mb-2">{languages[this.lang].event}: {this.lang === 'fa' ? detail.event.name_fa : detail.event.name_en}</Row>
                                    <Row className="mb-2">{languages[this.lang].description}: {detail.description}</Row>
                                    <Row className="mb-2">{languages[this.lang].transactionTime}: {detail.date}</Row>
                                </Col>
                                {detail.event_id === 1 ?

                                    detail.source === "Website" ?
                                        <Link to={setLink('/transactions', this.lang)}>
                                            <Button className="btn-round mx-2 px-5 py-2" color="primary" size="sm">
                                                {languages[this.lang].transactionsList}
                                            </Button>
                                        </Link>
                                        :
                                        <a href={'yekpay://transactions'}>
                                            <Button className="btn-round mx-2 px-5 py-2" color="primary" size="sm">
                                                {languages[this.lang].transactionsList}
                                            </Button>
                                        </a>
                                    : ''}

                            </div>
                        </Col>
                        <Col md="8" className="text-center mt-5">
                            <div className="mr-auto" navbar="">

                                <span>{languages[this.lang].share}: </span>
                                <EmailShareButton url={window.url}><EmailIcon size={32} round/></EmailShareButton>
                                <WhatsappShareButton url={window.url}><WhatsappIcon size={32}
                                                                                    round/></WhatsappShareButton>
                                <LinkedinShareButton url={window.url}><LinkedinIcon size={32}
                                                                                    round/></LinkedinShareButton>
                                <TelegramShareButton url={window.url}><TelegramIcon size={32}
                                                                                    round/></TelegramShareButton>
                                {/*<i className="fal fa-print ml-2"/>*/}
                                {/*<span>{languages[this.lang].print}</span>*/}
                            </div>
                        </Col>
                    </Row>
                </>
            );
        }
    }
}

export default connect(null, {showModal})(Callback);

/**
 * Created by morteza on 5/14/17.
 */
import {INIT_USER, LOGOUT_USER} from '../actions';
import {UserInit} from '../Values';

const userReducer = (state = UserInit, action) => {
    switch (action.type) {
        case INIT_USER:
            return {...state, ...action.user};
        case LOGOUT_USER:
            return {...UserInit};
        default:
            return state
    }
};

export default userReducer;
import React from "react";
import languages from "../../languages";
import SegmentedProgressbar from '../components/SegmentedProgressbar';
import { Link, withRouter } from 'react-router-dom'
import 'react-circular-progressbar/dist/styles.css';
import RequestServer from '../../RequestServer'
import {connect} from "react-redux";


// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators, InputGroup
} from "reactstrap";
import {getLang, setLink} from "../../Values";
import CurrencyText from "../../components/CurrencyText/CurrencyText";
import ReactTooltip from 'react-tooltip'

class Rtl extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
        activeIndex: 0,
        status: 'hasWallet',
        wallets: []
      };
      this.next = this.next.bind(this);
      this.previous = this.previous.bind(this);
      this.goToIndex = this.goToIndex.bind(this);
      this.onExiting = this.onExiting.bind(this);
      this.onExited = this.onExited.bind(this);
  }

  componentDidMount() {
    RequestServer('post', 'wallet/all', async (response) => {
      let wallets = [];
      response.forEach(resItem => {
        const currency = this.props.data.currencies.find(item => item.id === resItem.currency_id);
        if(currency && resItem.status === "Active")
          wallets.push({currency_id: currency.id, name: currency.name, code: currency.code, symbol: currency.symbol,
            balance: resItem.balance, pending: resItem.pending_balance});
      });
      this.setState({wallets, status: wallets.length > 0 ? "hasWallet" : "firstStage"});
    }, {
        token: this.props.userData.token
      });
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === this.state.wallets.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? this.state.wallets.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }


  renderProfileStatus = () => {
    return (
      <Col className="col-sm-4 col-lg-4 col-xs-12">
        <Card className="height-250  shadow-box">
          <CardBody className="text-center py-4 shadow-box">
            {/* <CircularProgressbar className="w-30" value={0.66} maxValue={1} text={`${0.66* 100}%`} styles={buildStyles({
            pathColor: "#843b90",
            textColor: "#843b90"
          })} /> */}
            <div style={{ width: "145px", height: "145px", margin: "0 auto" }}>
              <SegmentedProgressbar percentage={this.props.userData.strength} />
            </div>
            <h3 className="mt-4 mb-0">{languages[this.lang].profile}</h3>
          </CardBody>
        </Card>
      </Col>
    )
  };
  renderSteps = () => {
    if (this.state.status === 'firstStage') {
      return (
        <Row>
          {this.renderProfileStatus()}
          <Col className="col-sm-8 col-lg-8 col-xs-12">
            <div className="hover-cards">
            <Link className="shadow-box"  to={setLink('/wallet/create', this.lang)}>
            <Card className="height-250">
              <CardBody className="py-4 shadow-box">
                <div className="text-center float-left-home">
                  <img src={require('../../assets/img/wallet-image.png')} alt="" className="card-img mb-2" />
                </div>
                <div className="text-center">
                  <h3 className="align-middle col-md-pull-2 pt-6">{languages[this.lang].addyourwallet}</h3>
                </div>
              </CardBody>
            </Card>
            </Link>
            </div>
          </Col>
        </Row>
      )
    } else if (this.state.status === 'hasWallet') {
      const { activeIndex } = this.state;
      const slides = this.state.wallets.map((wallet) => {
        return (
          <CarouselItem
            onExiting={this.onExiting}
            onExited={this.onExited}
            key={wallet.currency_id}
            className="height-250 text-center"
          >
            <img className='dashboard-currency-arrow' src={require('../../assets/img/money-secure.png')} alt=""/>
            <div className='dashboard-currency-circle-back' />
            <h2>
              <CurrencyText
                value={wallet.balance}
                className="real-balance"
                isIrr={wallet.currency_id === 3}/>
              <br/>
              <CurrencyText
                value={wallet.pending}
                className="pending-balance"
                isIrr={wallet.currency_id === 3}/>
            </h2>
            <h1>{wallet.symbol}</h1>
          </CarouselItem>
        );
      });
      return (
        <Row>
          {this.renderProfileStatus()}
          <Col className="col-sm-5 col-lg-5 col-xs-12">
            <div className="hover-cards">
              <Link className="shadow-box" to={setLink('/bank-account/create', this.lang)}>
                <Card className="height-250">
                  <CardBody className="py-4 ">
                    <div className="text-center float-left-home">
                      <img src={require('../../assets/img/bank-account-image.png')} alt="" className="card-img mb-2" />
                    </div>
                    <div className="text-center">
                      <h3 className="align-middle col-md-pull-2 pt-6">{languages[this.lang].addYourBankAccount}</h3>
                    </div>
                  </CardBody>
                </Card>
              </Link>
            </div>
          </Col>
          <Col className="col-sm-3 col-lg-3 col-xs-12">
            <Card className="height-250">
              <CardBody className="py-4 ">
                <Carousel
                activeIndex={activeIndex}
                next={this.next}
                previous={this.previous}
                className='height-250'
                >
                  <CarouselIndicators items={this.state.wallets} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                  {slides}
                  <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                  <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                </Carousel>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )
    } else if (this.state.status === 'hasBankAccount') {
      const { activeIndex } = this.state;

      const slides = this.state.wallets.map((wallet) => {
        return (
          <CarouselItem
            onExiting={this.onExiting}
            onExited={this.onExited}
            key={wallet.currency_id}
            className="height-250 text-center"
          >
            <img className='dashboard-currency-arrow' src={require('../../assets/img/money-secure.png')} alt="" />
            <div className='dashboard-currency-circle-back' />
            <h2>{wallet.balance}</h2>
            <h1>{wallet.symbol}</h1>
          </CarouselItem>
        );
      });
      return (
        <Row>
        {this.renderProfileStatus()}
        <Col className="col-sm-5 col-lg-5 col-xs-12">
          <Link className="shadow-box" to={setLink('/personal-gateway/start', this.lang)}>
            <div className="hover-cards">
              <Card className="height-250">
                <CardBody className="py-4 ">
                  <div className="text-center float-left-home">
                    <img src={require('../../assets/img/yekpay-personal-payment-gateway-image.png')} alt="" className="card-img mb-2" />
                  </div>
                  <div className="text-center">
                    <h3 className="align-middle col-md-pull-2 pt-6">{languages[this.lang].addyourpersonalgateway}</h3>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Link>
        </Col>
        <Col className="col-sm-3 col-lg-3 col-xs-12">
          <Card className="height-250">
            <CardBody className="py-4 shadow-box">
              <Carousel
              activeIndex={activeIndex}
              next={this.next}
              previous={this.previous}
              className='height-250'
              >
                <CarouselIndicators items={this.state.wallets} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                {slides}
                <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
              </Carousel>
            </CardBody>
          </Card>
        </Col>
      </Row>
      )
    }
  };
  render() {
    this.lang = getLang(this.props);
    return (
      <>
        <div className="content">
          {this.renderSteps()}
          <div className="hover-cards">
            <Row>
              <Col className="col-sm-6 col-lg-3 col-xs-12">
                <Card>
                  <Link className="shadow-box" to={setLink('/transactions', this.lang)}>
                    <CardBody className="text-center py-4">
                      <img src={require('../../assets/icons/bill.svg')} alt="" className="card-icon mb-4" />
                      <h4 className="mb-0">{languages[this.lang].transactionsList}</h4>
                    </CardBody>
                  </Link>
                </Card>
              </Col>
              <Col className="col-sm-6 col-lg-3 col-xs-12">
                <Card>
                  <Link className="shadow-box" to={setLink('/payment-gateway', this.lang)}>
                    <CardBody className="text-center py-4">
                      <img src={require('../../assets/icons/online-payment-5.svg')} alt="" className="card-icon mb-4" />
                      <h4 className="mb-0">{languages[this.lang].websiteGateway}</h4>
                    </CardBody>
                  </Link>
                </Card>
              </Col>
              <Col className="col-sm-6 col-lg-3 col-xs-12">
                <Card>
                  <Link className="shadow-box" to={setLink('/yekpay-link/start', this.lang)}>
                    <CardBody className="text-center py-4">
                      <img src={require('../../assets/icons/online-payment-6.svg')} alt="" className="card-icon mb-4" />
                      <h4 className="mb-0">{languages[this.lang].yekpayLink}</h4>
                    </CardBody>
                  </Link>
                </Card>
              </Col>
              {/*<Col className="col-sm-6 col-lg-3 col-xs-12">*/}
                {/*<Card>*/}
                  {/*<Link to={setLink('/send-money', this.lang)}>*/}
                    {/*<CardBody className="text-center py-4">*/}
                      {/*<img src={require('../../assets/icons/pay.svg')} alt="" className="card-icon mb-4" />*/}
                      {/*<h4 className="mb-0">{languages[this.lang].moneyexchange}</h4>*/}
                    {/*</CardBody>*/}
                  {/*</Link>*/}
                {/*</Card>*/}
              {/*</Col>*/}
              <Col className="col-sm-6 col-lg-3 col-xs-12">
                <Link className="shadow-box" to={setLink('/personal-gateway', this.lang)}>
                  <Card>
                    <CardBody className="text-center py-4">
                      <img src={require('../../assets/icons/online-payment-5.svg')} alt="" className="card-icon mb-4" />
                      <h4 className="mb-0">{languages[this.lang].personalGateway}</h4>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col className="col-sm-6 col-lg-3 col-xs-12">
                <Card>
                  <Link className="shadow-box" >
                    <CardBody className="text-center py-4">
                      <img src={require('../../assets/icons/credit-card-2.svg')} alt="" className="card-icon mb-4" />
                      <h4 className="mb-0">{languages[this.lang].POS}</h4>
                    </CardBody>
                  </Link>
                  <ReactTooltip place="top" type="success" effect="solid" />
                </Card>

              </Col>
              <Col className="col-sm-6 col-lg-3 col-xs-12">
                <Card>
                  <Link className="shadow-box" >
                    <CardBody className="text-center py-4">
                      <img src={require('../../assets/icons/profits.svg')} alt="" className="card-icon mb-4" />
                      <h4 className="mb-0">{languages[this.lang].yekstock}</h4>
                    </CardBody>
                  </Link>
                </Card>
              </Col>
              <Col className="col-sm-6 col-lg-3 col-xs-12">
                <Card>
                  <Link className="shadow-box" >
                    <CardBody className="text-center py-4">
                      <img src={require('../../assets/icons/transaction.svg')} alt="" className="card-icon mb-4" />
                      <h4 className="mb-0">{languages[this.lang].issueDebitCard}</h4>
                    </CardBody>
                  </Link>
                </Card>
              </Col>
              <Col className="col-sm-6 col-lg-3 col-xs-12">
                <Link className="shadow-box" to={setLink('/wallet', this.lang)}>
                  <Card>
                    <CardBody className="text-center py-4">
                      <img src={require('../../assets/icons/wallet.svg')} alt="" className="card-icon mb-4" />
                      <h4 className="mb-0">{languages[this.lang].wallet}</h4>
                    </CardBody>
                  </Card>
                </Link>
              </Col>

            </Row>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      userData: state.userReducer,
      data: state.dataReducer
  }
};

export default withRouter(connect(mapStateToProps)(Rtl));

import React from "react";
import languages from "../../languages";
// reactstrap components
import {
    Row,
    Col
} from "reactstrap";
import RequestServer from "../../RequestServer";
import Loading from "../components/Loading";
import {getLang} from "../../Values";
import {connect} from "react-redux";
import {showModal} from "../../actions";

class Reciept extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            detail: undefined,
        };
    }

    componentDidMount() {
        this.request();
    }

    request = () => {
        RequestServer('post', 'boot', response => {
            this.makeRemoteRequest(response);
        }, {
            post: { secret: "AppYek**175GHD@#" },
            lang: this.lang,
            onAnyFailed: () => {
                this.setState({loading: false});
            }
        });
    };

    makeRemoteRequest = (bootData) => {
        const {params} = this.props.match;
        RequestServer('post', "verify-transaction", (response) => {
            response['currency'] = bootData.currencies.find(item => item.id === response.currency_id);
            this.setState({detail: response, loading: false});
        }, {
            post: {oid: params.oid, authority: params.authority},
            lang: this.lang,
            thisProps: {data: bootData, showModal: this.props.showModal},
            onAnyFailed: () => {
                this.setState({loading: false});
            }
        });
    };

    render() {
        this.lang = getLang(this.props);
        const {loading, detail} = this.state;

        if(loading || ! detail)
            return (<Loading lang={this.lang} />);
        else {
            const receiptClass = `receipt-container${detail.success === 1? '': '-failed'}`;
            return (
                <>
                    <Row className="justify-content-center mt-5">
                        <Col lg="8">
                            <div className={receiptClass}>
                                <h4>{languages[this.lang].mastercardreciept}</h4>
                                <div className="receipt-footer"/>
                                <Col md="6" className="mx-auto">
                                    <Row>{languages[this.lang].trackingCode}: {detail.tracking_code}</Row>
                                    <Row>{languages[this.lang].amount}: {detail.amount} {detail.currency.symbol}</Row>
                                </Col>
                            </div>
                        </Col>
                        <Col md="8" className="text-center mt-5">
                            <div className="mr-auto" navbar="">
                                <i className="far fa-save ml-2"/>
                                <span className="ml-5">{languages[this.lang].save}</span>
                                <i className="far fa-share-alt ml-2"/>
                                <span className="ml-5">{languages[this.lang].share}</span>
                                <i className="fal fa-print ml-2"/>
                                <span>{languages[this.lang].print}</span>
                            </div>
                        </Col>
                    </Row>
                </>
            );
        }
    }
}

export default connect(null, {showModal})(Reciept);
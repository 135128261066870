import React, {Component} from 'react';
import { connect } from 'react-redux';
import {hideModal} from '../actions';
import PropTypes from "prop-types";
import ReactBSAlert from "react-bootstrap-sweetalert";
import languages from "../languages";

class QuestionDialog extends Component {
    onConfirm = () => {
        if(this.props.onConfirm !== undefined)
            this.props.onConfirm();
        window.location.reload(false);
    };

    onReject = () => {
        if(this.props.onReject !== undefined)
            this.props.onReject();
        this.props.hideModal();
    };

    render() {
        const {status, title, description, yesText, noText, hideNoBtn, lang} = this.props;
        return (
            <ReactBSAlert
                status={status}
                title={title}
                style={{ display: "block", marginTop: "-100px" }}
                onConfirm={this.onConfirm}
                onCancel={this.onReject}
                confirmBtnBsStyle={status}
                confirmBtnText={yesText? yesText: languages[lang].confirm}
                cancelBtnText={!hideNoBtn? (noText? noText: languages[lang].cancel): undefined}
            >
                {description}
            </ReactBSAlert>
        );
    }
}

QuestionDialog.propTypes = {
    status: PropTypes.oneOf(['default', 'info', 'success', 'warning', 'danger', 'error', 'input']),
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    onConfirm: PropTypes.func,
    onReject: PropTypes.func,
    yesText: PropTypes.string,
    noText: PropTypes.string,
    hideNoBtn: PropTypes.bool,
    lang: PropTypes.string
};

QuestionDialog.defaultProps = {
    status: 'success',
    lang: 'en'
};


export default connect(null, { hideModal })(QuestionDialog);

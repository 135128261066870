import React from "react";
import languages from "../../languages";
// nodejs library that concatenates classes
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { initUser, logoutUser } from '../../actions';
import {userIsLoggedIn, getLang, setLink} from '../../Values';
import { Link } from 'react-router-dom'

// reactstrap components
import {
  Button,
  Collapse,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  Nav,
  NavLink,
  Container,
  Modal,
  DropdownToggle,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      modalSearch: false,
      color: "navbar-transparent",
    };

    this.toggle = this.toggle.bind(this);
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateColor);
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white"
      });
    } else {
      this.setState({
        color: "navbar-transparent"
      });
    }
  };
  // this function opens and closes the collapse on small devices
  toggleCollapse = () => {
    if (this.state.collapseOpen) {
      this.setState({
        color: "navbar-transparent"
      });
    } else {
      this.setState({
        color: "bg-white"
      });
    }
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  // this function is to open the Search modal
  toggleModalSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch
    });
  };

  toggle(e, language) {
    const pathnameArr = this.props.location.pathname.split('/');
    if(pathnameArr.length > 1){
      const path = this.props.location.pathname.replace(/^\/[a-z]{2}\/*/, "/");
      window.location.assign(setLink(path, language));
    }
    e.preventDefault();
  }

  render() {
    this.lang = getLang(this.props);
    return (
      <>
        <Navbar
          className={classNames("navbar-absolute", {
            [this.state.color]: !document.body.classList.contains(
              "full-screen-map"
            )
          })}
          expand="lg"
          style={{ top: 0 }}
        >
          <Container fluid>
            <div className="navbar-wrapper">
              <div className="navbar-minimize d-inline">
                <Button
                  className="minimize-sidebar btn-just-icon"
                  color="link"
                  id="tooltip209599"
                  onClick={this.props.handleMiniClick}
                >
                  <i className="tim-icons icon-align-center visible-on-sidebar-regular" />
                  <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini" />
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="tooltip209599"
                  placement="right"
                >
                  Sidebar toggle
                </UncontrolledTooltip>
              </div>
              <div
                className={classNames("navbar-toggle d-inline", {
                  toggled: this.props.sidebarOpened
                })}
              >
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.props.toggleSidebar}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              <NavbarBrand href="#pablo" onClick={e => e.preventDefault()}>
                {this.props.brandText}
              </NavbarBrand>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navigation"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            <Collapse navbar isOpen={this.state.collapseOpen}>
              <Nav navbar>
              <UncontrolledDropdown nav className="dropdown-search-bar">
                  <DropdownToggle

                    caret
                    color="default"
                    data-toggle="dropdown"
                    nav
                  >
                    <i className="fal fa-globe mx-2" />
                    <span>{this.lang === 'en'? 'English': 'فارسی'}</span>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-navbar" right tag="ul">
                    <NavLink tag="li">
                      <a href="#" onClick={(e) => this.toggle(e, 'fa')}>
                        <DropdownItem className="nav-item pointer">
                          فارسی
                        </DropdownItem>
                      </a>
                    </NavLink>
                    <NavLink tag="li">
                      <a href="#" onClick={(e) => this.toggle(e, 'en')}>
                        <DropdownItem className="nav-item pointer">
                          English
                        </DropdownItem>
                      </a>
                    </NavLink>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <InputGroup className="search-bar" tag="li">
                  <Link className="mx-3" to={setLink('/exchange-rates', this.lang)}>
                    <i className="fal fa-chart-bar mx-2" />
                    <span>{languages[this.lang].exchangeRate}</span>
                  </Link>
                </InputGroup>
                <InputGroup className="search-bar" tag="li">

                  <Link className="mx-3" to={setLink('/ticket', this.lang)}>
                    <i className="fal fa-envelope mx-2" />
                    <span>{languages[this.lang].tickets}</span>
                  </Link>
                </InputGroup>

                {/* <InputGroup className="search-bar" tag="li">
                  <i className="tim-icons icon-email-85 ml-3" />
                  <span>{languages[lang].messages}</span>
                </InputGroup> */}
                <InputGroup className="search-bar" tag="li">
                  {userIsLoggedIn(this.props.userData) && (
                    <a href="#" className="mx-3" onClick={this.logOut}>
                      <i className="fal fa-power-off mx-2" />
                      <span>{languages[this.lang].exit}</span>
                    </a>
                  )}
                </InputGroup>

              </Nav>
            </Collapse>
          </Container>
        </Navbar>
        <Modal
          modalClassName="modal-search"
          isOpen={this.state.modalSearch}
          toggle={this.toggleModalSearch}
        >
          <div className="modal-header">
            <Input id="inlineFormInputGroup" placeholder="SEARCH" type="text" />
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.toggleModalSearch}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
          </div>
        </Modal>
      </>
    );
  }

  logOut = () => {
    this.props.logoutUser();
    setTimeout(() => {
      this.props.history.push(setLink('/auth/login', this.lang));
    }, 500);
  };
}

const mapStateToProps = (state) => {
  return {
    userData: state.userReducer
  }
};

export default withRouter(connect(mapStateToProps, { initUser, logoutUser })(AdminNavbar));

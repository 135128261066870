import React from 'react';
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";

const CurrencyText = (props) => {
  const {value, prefix, suffix, isIrr, className} = props;
  return (
    <NumberFormat
      className={className}
      displayType={'text'}
      value={value}
      thousandSeparator={true}
      prefix={prefix}
      suffix={suffix}
      decimalScale={isIrr? 0: 2}
      fixedDecimalScale={!isIrr}/>
  );
};

CurrencyText.propTypes = {
  value: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  isIrr: PropTypes.bool,
  className: PropTypes.string
};

export default CurrencyText;
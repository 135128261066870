import React, {Component} from 'react';
import { connect } from 'react-redux';

import QuestionDialog from './QuestionDialog';
import ConfirmMobileDialog from './ConfirmMobileDialog';
import ShareYekpayLinkDialog from './ShareYekpayLinkDialog';

const MODAL_COMPONENTS = {
    QuestionDialog, ConfirmMobileDialog, ShareYekpayLinkDialog
};

class ModalRoot extends Component {
    render(){
        const {modal, props} = this.props.dialog;
        if (!modal)
            return null;

        const ModalComponent = MODAL_COMPONENTS[modal];
        return <ModalComponent {...props}/>;
    }
}

const mapStateToProps = state => ({ dialog: state.dialogReducer });
export default connect(mapStateToProps)(ModalRoot);

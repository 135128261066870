 // import VectorMap from "views/maps/VectorMap.jsx";
// import GoogleMaps from "views/maps/GoogleMaps.jsx";
// import FullScreenMap from "views/maps/FullScreenMap.jsx";
// import ReactTables from "views/tables/ReactTables.jsx";
// import RegularTables from "views/tables/RegularTables.jsx";
// import ExtendedTables from "views/tables/ExtendedTables.jsx";
// import Wizard from "views/forms/Wizard.jsx";
// import ValidationForms from "views/forms/ValidationForms.jsx";
// import ExtendedForms from "views/forms/ExtendedForms.jsx";
// import RegularForms from "views/forms/RegularForms.jsx";
// import Calendar from "views/Calendar.jsx";
// import Widgets from "views/Widgets.jsx";
// import Charts from "views/Charts.jsx";
// import Dashboard from "views/Dashboard.jsx";
// import Buttons from "views/components/Buttons.jsx";
// import SweetAlert from "views/components/SweetAlert.jsx";
// import Notifications from "views/components/Notifications.jsx";
// import Grid from "views/components/Grid.jsx";
// import Typography from "views/components/Typography.jsx";
// import Panels from "views/components/Panels.jsx";
// import Icons from "views/components/Icons.jsx";
// import Pricing from "views/pages/Pricing.jsx";

import Rtl from "views/pages/Rtl.jsx";

import PaymentLinkHome from "views/payment-link/PaymentLinkHome.jsx";
import Reciept from "views/payment-link/Reciept.jsx";

import Error from "./views/error/Error";

import Callback from "./views/payment/Callback";

const routes = [



  {
    path: "/home", // /home
    name: "Home",
    rtlName: "خانه",
    icon: "fal fa-tachometer",
    component: Rtl,
    layout: "/dashboard"
  },


  {
    path: "/verify/:oid/:authority",
    name: "Receipt",
    rtlName: "رسید",
    component: Reciept,
    icon: "tim-icons icon-chart-pie-36",
    layout: "/public",
    disableMenu: true,
  },
  {
    path: "/:username",
    name: "Payment Link2",
    rtlName: "خانه لینک پرداخت",
    component: PaymentLinkHome,
    icon: "tim-icons icon-chart-pie-36",
    layout: "/public",
    disableMenu: true,
  },
  {
    path: "/not-found",
    name: "Not Found",
    rtlName: "پیدا نشد",
    component: Error,
    icon: "tim-icons icon-chart-pie-36",
    layout: "/public",
    disableMenu: true,
  },
  {
    path: "/payment/:authority/:signature",
    name: "Transaction Receipt",
    rtlName: "رسید تراکنش",
    component: Callback,
    icon: "tim-icons icon-chart-pie-36",
    layout: "/public",
    disableMenu: true,
  },


];

export default routes;

let languages = {
    fa: {
        'yes': 'بله',
        'deleteSure': 'آیا از حذف این آیتم اطمینان دارید ؟',
        'changeSure': 'آیا از تفییر وضعیت این آیتم اطمینان دارید ؟',
        'yourRequestRegistered': 'درخواست شما با موفقیت ثبت شد.',
        'submit': 'ثبت',
        'fillBillingForm': 'لطفا اطلاعات زیر را تکمیل نمایید.',
        'refundReason': 'علت ریفاند',
        'refunded': 'بازگشت داده شد',
        'yourActivePlan': 'طرح فعال شما',
        'choose': 'انتخاب گزینه',
        'uploadProfilePicture': 'آپلود تصویر پروفایل',
        'verifyMobile': 'تایید شماره همراه',
        'active': 'فعال',
        'inactive': 'غیر فعال',
        'enter5DigitNumber': 'کد ۵ رقمی ارسال شده به شماره همراه خود را وارد نمایید.',
        'optional': 'اختیاری',
        'businessPlus': 'کسب و کار ویژه',
        'merchantCode': 'کد مرچنت',
        'trial': 'آزمایشی',
        'availableAmount': 'مبلغ قابل برداشت',
        'yourResponse': 'پاسخ شما',
        'supportResponse': 'پاسخ پشتیبانی',
        'logout': 'خروج',
        'pleaseChooseYourUsername': 'لطفا شناسه درگاه پرداخت شخصی خود را وارد نمایید.',
        'editGateway': 'ویرایش درگاه',
        'pleaseEnterGateWayInformation': 'لطفا اطلاعات وب سایت خود را وارد نمایید.',
        'pleaseEnterGateWayTechnicalInformation': 'لطفا اطلاعات فنی وب سایت خود را وارد نمایید.',
        'pleaseEnterGateWayContactInformation': 'لطفا اطلاعات تماس وب سایت خود را وارد نمایید.',
        'individual': 'حقیقی',
        'corporate': 'حقوقی',
        'uploadLogo': 'بارگذاری لوگو',
        'bankAccountReceiptTooltip': 'این ورودی برای حساب های ریالی اجباری نمی باشد. برای حساب های ارزی می بایست شامل تصویری از رسید بانکی شامل نام صاحب حساب و شماره شبا باشد.',
        'pleaseEnterYekpayLinkInformation': 'لطفا اطلاعات لینک پرداخت را وارد نمایید',
        'pleaseSelectYekpayLinkImage': 'لطفا تصویر لینک پرداخت را انتخاب نمایید',
        'uploadImage': 'آپلود تصویر',
        'userAgreement': 'شرایط و قوانین استفاده از سرویس های سایت یک پی را مطالعه نموده و با کلیه موارد آن موافقم.',
        'myYekpayLinksDesc': 'با توجه به اینکه سطح حساب کاربری شما شخصی می باشد، شما می توانید تنها یک لینک پرداخت فعال داشته باشید. جهت فعال کردن لینک پرداخت دیگر می بایست ابتدا لینک پرداخت فعال خود را به حالت تعلیق شده در بیاورید. توجه داشته باشید در صورت حدف لینک پرداخت امکان بازگشت آن دیگر وجود نخواهد داشت. ',
        'changeStatus': 'تغییر وضعیت',
        'delete': 'حذف',
        'firstNameCanNotChange': 'نام وارد شده پس از ثبت نام قابل تغییر نخواهد بود.',
        'lastNameCanNotChange': 'نام خانوادگی وارد شده پس از ثبت نام قابل تغییر نخواهد بود.',
        'emailCanNotChange': 'ایمیل وارد شده پس از ثبت نام قابل تغییر نخواهد بود.',
        'promotionCodeTooltip': 'در صورت داشتن کد معرف آن را وارد نمایید.',
        'bankAccountImageValidation': '(حداکثر: ۲ مگابایت, jpg,png)',
        'Upload': 'بارگذاری',
        'passportUploadTooltip': 'لطفا کارت ملی یا گذر نامه خود را در کنار صورت خود قرار داده و تصویر آن را به صورتی که چهره شما واضح و اطلاعات کارت شما خوانا باشد، ارسال نمایید.',
        'passport/identity': 'پاسپورت/کارت ملی',
        'profilePicture': 'عکس پروفایل',
        'yekpayLinkDescription': 'شرح خدمات/کالای فروخته شده',
        'yekpayLinkDescriptionPlaceholder': 'لطفا جزئیات مربوط به فروش خدمات یا محصولات خود را وارد نمایید.',
        'yekpayLinkDPayerescriptionPlaceholder': 'لطفا جزئیات مربوط به خدمات یا محصولاتی که خریداری نموده اید را بیان کنید.',
        'name': 'نام',
        'euroLimitation': 'حداقل: ۵ یورو - حداکثر: ۲۵۰۰ یورو',
        'tryLimitation': 'حداقل: ۱۰ لیر - حداکثر: ۱۵۰۰۰ لیر',
        'gbpLimitation': 'حداقل: ۵ پوند - حداکثر: ۲۰۰۰ پوند',
        'aedLimitation': 'حداقل: ۱۰ درهم - حداکثر: ۱۰۰۰۰ درهم',
        'yekpay_link_form_title': 'لطفا فرم زیرا را با دقت تکمیل نمایید. در غیر این صورت تراکنش شما ممکن است لغو گردد.',


        'successful': 'موفق',
        'unSuccessful': 'ناموفق',
        'ok': 'باشه',
        'fullName': 'نام و نام خانوادگی',
        'description': 'توضیحات',

        'email': 'ایمیل',
        'password': 'کلمه عبور',
        'login': 'ورود',
        'doNotHaveAccount': 'حساب کاربری ندارید؟',
        'createAccount': 'ساخت حساب کاربری',
        'forgotPasswordQuestion': 'کلمه عبور خود را فراموش کرده اید؟',
        'loginDescription': 'یک پی اولین و تنها پلتفرم پرداخت آنلاین در حوزه ریالی و ارزی است که با هدف تسهیل تراکنش های مالی بین المللی برای افراد و کسب و کارها در ایران و خارج از کشور سرویس های سریع، مطمئن و مقرون به صرفه‌ای را در بستر امن بانکی ارائه می دهد.',

        'forgotPassword': 'فراموشی کلمه عبور',
        'forgotPasswordEmailSent': 'کد تایید به آدرس پست الکترونیک شما ارسال گردید.',
        'confirmationCode': 'کد تایید',
        'confirmationCodeAccepted': 'کد شما تایید گردید. می توانید کلمه عبور جدید خود را تعریف نمایید.',
        'passwordChangedSuccessfully': 'کلمه عبور شما با موفقیت تغییر یافت.',

        'register': 'ثبت نام',
        'firstName': 'نام',
        'lastName': 'نام خانوادگی',
        'promotionCode': 'کد معرف',
        'haveAccount': 'حساب کاربری دارید؟',
        'mobile': 'شماره همراه',
        'phone': 'تلفن',


        'emailActivation': 'فعال سازی پست الکترونیک',
        'emailVerificationCodeSent': 'کد تایید پست الکترونیک برای شما ایمیل گردید.',
        'emailVerificationDescription': `یک کد ۵ رقمی به آدرس پست الکترونیکی شما ارسال گردیده است. لطفا کد ارسالی را وارد نمایید.`,
        'sendEmailVerificationAgain': 'درصورت عدم دریافت ایمیل اینجا کلیک نمایید.',
        'doneSuccessfully': 'با موفقیت انجام گردید.',


        'topup': 'شارژ کیف پول',
        'chargeWalletTitle': 'شارژ کیف پول',
        'chargeWalletDescription': 'شما می توانید کیف پول های ریالی و ارزی خود را با کارت شتابی یا کارت های بین المللی به صورت آنی و یا به وسیله حوالی بانکی (بسته به زمان واریز) شارژ نمایید.',
        'selectChargeWalletMethod': 'روش خود جهت شارژ را انتخاب نمایید',
        'shetabCards': 'کارت‌های شتابی',
        'wire': 'حواله',
        'creditCards': 'کارت‌های ارزی',
        'irrTopupDescription': 'شما در صورت داشتن کیف پول ریال می توانید به وسیله کارت های شتاب کیف پول خود را به صورت آنلاین شارژ نمایید. حداقل مبلغ شارژ در هر مرتبه 10،000 ریال و حداکثر 500،000،000 ریال می باشد. پس از پرداخت موفق، کیف پول ریال شما پس از 3 ساعت شارژ خواهد شد. ضمنا تراکنش های با ارز لیر شامل ۱۸ درصد مالیات بر ارزش افزوده خواهد شد.',
        'irrTopupTitle': 'شارژ کیف پول - کارت های شتابی',
        'wireTopupTitle': 'شارژ کیف پول - حواله بانکی',
        'wireTopupDescription': 'شما می توانید کیف پول خود را به وسیله حواله بانکی شارژ نمایید. بدین منظور می بایست فرم زیر را بر اساس اطلاعات واریز کننده پر نمایید. پس از تایید اطلاعات طی 24 ساعت ایمیلی حاوی اطلاعات حساب بانکی به آدرس ایمیل شما ارسال خواهد شد و پس از واریز وجه در حساب اعلام شده کیف پول شما به همان مقدار شارژ خواهد شد.',
        'creditTopupTitle': 'شارژ کیف پول - کارت های ارزی',
        'creditTopupDescription': 'شما می توانید کیف پول های ارزی خود را با استفاده از کارت های ارزی که دارای 3DSecure باشند به صورت آنلاین شارژ نمایید. پس از پرداخت موفق، کیف پول مورد نظر شما پس از 3 ساعت شارژ خواهد شد. ',

        'newWallet': 'کیف پول جدید',


        'walletCharge': 'شارژ کیف پول',

        'transactionReceipt': 'رسید تراکنش',
        'transactionTime': 'زمان تراکنش',
        'transactionStatus': 'وضعیت تراکنش',


        'donthaveanacco': 'حساب کاربری ندارید؟',
        'haveanacco': 'حساب کاربری دارید؟',
        'createacc': 'ساخت حساب کاربری',
        'forgotpassqestion': 'کلمه عبور خود را فراموش کرده اید ؟',


        'pending': 'در انتظار تایید',
        'suspend': 'تعلیق شده',
        'declined': 'رد شده',

        'payout': 'برداشت از کیف پول',
        'payoutDescription': 'شما میتوانید موجودی کیف پول خود را به حساب بانکی خود در داخل کشور توسط انتقال پایا و یا از طریق ارسال حواله به حساب های خارج از کشور واریز نمایید. کارمزد خدمات یک پی به شرح زیر است',
        'selectPayoutMethod': 'روش خود جهت برداشت را انتخاب نمایید',
        'payoutIRRTitle': 'برداشت از کیف پول - برداشت ریالی',
        'payoutIRRDescription': 'شما می توانید موجودی کیف پول ریالی خود را به حساب بانکی که تعریف کرده اید ارسال نمایید. این امکان در روزهای کاری بانک های ایران از شنبه تا چهارشنبه و از ساعت 8 صبح الی 3:30 بعد از ظهر میسر می باشد. لازم به ذکر است مجموع مبلغ درخواستی و کمیسیون نباید از موجودی کیف پول ریالی شما بیشتر باشد.',
        'payoutTransferTitle': 'برداشت از کیف پول - به حساب یک پی دیگر',
        'payoutTransferDescription': 'شما می توانید موجودی هر یک از کیف پول های خود را به کیف پول های کاربران دیگر یک پی به صورت آنی و بدون کمیسیون انتقال دهید. لازم به ذکر است جهت انتقال می بایست طرف مقابل نیز کیف پول ارز مورد نظر جهت انتقال را دارا باشد.',
        'payoutWireTitle': 'برداشت از کیف پول - انتقال حواله',
        'payoutWireDescription': 'شما می توانید موجودی کیف پول خود را به حساب های بانکی که تعریف کرده اید به صورت حواله بانکی انتقال دهید. بدین منظور پس از تکمیل فرم زیر، مبلغ مورد نظر شما به وسیله حواله بانکی به حساب شما واریز خوهد شد.',
        'IRRPayout': 'برداشت ریالی',
        'wireTransfer': 'حواله بانکی',


        'bankAccount': 'حساب بانکی',
        'holderName': 'دارنده حساب',


        'companyName': 'نام شرکت',

        'download': 'دانلود',
        'buyPlugin': 'خرید پلاگین',

        'exchangeTitle': 'تبادل ارز',
        'exchangeDescription': 'جهت تبادل ارز لطفا کیف پول مبدا، مقصد و مبلغ درخواستی جهت تبادل را انتخاب نمایید. توجه داشته باشید هر گونه تبادل ارز شامل 500 ریال کارمزد در تبادل های با ارز مبدا ریال و 30 سنت در تبادل های با ارز مبدا غیر از ریال می گردد.',
        'fromCurrency': 'ارز مبدا',
        'toCurrency': 'ارز مقصد',


        'yekpayLinkName': 'نام یک پی لینک',
        'operations': 'عملیات',


        'yekpay': 'یک پی',
        'wallet': 'کیف پول',
        'wrongPassword': 'نام کاربری و کلمه عبور همخوانی ندارند.',
        'reason': 'دلیل درخواست',
        'refundTitle': 'درخواست بازگشت پول',
        'refund': 'بازگشت پول',
        'verify': 'تایید',
        'email_has_taken': 'یک حساب کاربری با پست الکترونیکی وارد شده موجود می باشد.',
        'yekpaydashboard': 'داشبورد یک پی',
        'tickets': 'تیکت ها',
        'messages': 'پیام ها',
        'exit': 'خروج',
        'dollar': 'دلار',
        'euro': 'یورو',
        'copyright': 'کلیه حقوق متعلق به وبسایت یک پی می باشد.',
        'chooseimage': 'انتخاب تصویر',
        'edit': 'ویرایش',
        'remove': 'حذف',
        'loading': 'درحال بارگذاری',
        'addbankacc': 'افزودن حساب بانکی',
        'shabanum': 'شماره شبا',
        'swiftnum': 'کد سوییفت',
        'swiftplaceh': 'کد سوئیفت برای حساب بانکی ایرانی ضروری نیست',
        'accholdername': 'نام صاحب حساب',
        'nameandfamilyname': 'نام و نام‌خانوادگی',
        'bankandbranch': 'نام بانک و شعبه',
        'bankandbranchplaceh': 'ملت - اجودانیه',
        'recieptstatusupload': 'لطفا وضعیت حساب یا رسید بانکی خود را آپلود نمایید',
        'uploadreciept': 'آپلود رسید',
        'add': 'افزودن',
        'mybankaccounts': 'حساب های بانکی من',
        'addbankaccounts': 'افزودن حساب بانکی',
        'start': 'شروع',
        'end': 'پایان',
        'renew': 'تازه سازی',
        'bankaccounttitle': 'حساب بانکی',
        'bankaccountdesc': 'جهت واریز موجودی کیف پول خود به حساب های ریالی و یا ارزی، به تعریف حساب بانکی به نام خود نیاز دارید. جهت ثبت حساب بانکی، فرم مربوطه را تکمیل نمایید سپس کارشناسان پشتیبانی تا 24 ساعت نسبت به تایید یا رد آن اقدام خواهند کرد.',
        'bankaccountlist': 'لیست حساب های بانکی',
        'status': 'وضعیت',
        'createddate': 'تاریخ ایجاد',
        'documents': 'مستندات',
        'codesandissues': 'نمونه کد و خطاها',
        'developerstitle': 'راهنمای برنامه نویسان',
        'developersdesc': 'چنانچه از CMS خاصی استفاده می کنید، می توانید پلاگین مورد نیاز خود را از بخش پلاگین ها خریداری و دانلود نمایید. همچنین می توانید از داکیومنت های موجود در بخش مستندات برای راه اندازی درگاه بر روی وب سایت خود استفاده نمایید.',
        'plugins': 'پلاگین ها',
        'codes': 'مستندات',
        'pluginshop': 'فروشگاه پلاگین یک پی',
        'woocommerce': 'Woocommerce',
        'version': 'نسخه',
        'language': 'زبان',
        'currency': 'ارز',
        'selectwallet': 'انتخاب کیف پول',
        'confirm': 'تایید',
        'billreciept': 'رسید تراکنش',
        'continue': 'ادامه',
        'back': 'قبل',
        'complete': 'تکمیل',
        'payoutfrom': 'برداشت از',
        'amount': 'مبلغ',
        'value': 'مقدار',
        'converto': 'تبدیل به',
        'select': 'انتخاب گزینه',
        'confirmexchange': 'تایید تبادل ارز',
        'date': 'تاریخ',
        'save': 'ذخیره',
        'print': 'پرینت',
        'share': 'اشتراک گذاری',
        'mastercardtitle': 'مستر کارت',
        'mastercarddesc': 'برای تبادل ارز لطفا کیف پول مبدا و مقصد خود را انتخاب بفرمایید توجه داشته باشید هرگونه تبادل ارز شامل مالیات خواهد شد. برای تبادل ارز لطفا کیف پول مبدا و مقصد خود را انتخاب بفرمایید توجه داشته باشید هرگونه تبادل ارز شامل مالیات خواهد شد.',
        'mymastercards': 'کارت های من',
        'addmastercard': 'اضافه کردن کارت',
        'balance': 'موجودی',
        'hour': 'ساعت',
        'mastercardname': 'نام کارت',
        'mastercardwiztittle': 'قبل از پرداخت میبایست فرم زیر را بر اساس اطلاعات پرداخت کننده تکمیل کنید',
        'birthdate': 'تاریخ تولد',
        'familyname': 'نام خانوادگی',
        'country': 'کشور',
        'city': 'شهر',
        'address': 'آدرس',
        'postalcode': 'کد پستی',
        'nationality': 'ملیت',
        'nationalcode': 'کد ملی',
        'passnum': 'شماره پاسپورت',
        'telephonenum': 'شماره تماس',
        'uploadpass': 'آپلود پاسپورت',
        'uploaddoc': 'آپلود سند',
        'mastercardreciept': 'رسید صدور کارت بین المللی',
        'paywith': 'پرداخت با کیف',
        'personalinfo': 'مشخصات فردی',
        'locationinfo': 'موقعیت فعلی',
        'contactinfo': 'اطلاعات تماس',
        'docs': 'مستندات',
        'reciept': 'رسید',
        'issueDebitCard': 'صدور کارت بین المللی',
        'mywallettitle': 'ساخت کیف پول',
        'mywalletdesc': 'جهت انجام تراکنش نیاز به تعریف کیف پول می باشد. شما می توانید بسته به نوع اکانت خود کیف پول های مورد نظر خود را اضافه نمایید. توجه داشته باشید امکان تغییر و یا حذف کیف پول هایی که بر روی آن تراکنشی (موفق و یا نا موفق) انجام گردیده وجود ندارد. ',
        'yoanwallet': 'کیف پول یو آن',
        'rialwallet': 'کیف پول ریال',
        'eurowallet': 'کیف پول یورو',
        'dollarwallet': 'کیف پول دلار',
        'chooseYourWallet': 'کیف پول خود را انتخاب کنید',
        'lirwallet': 'کیف پول لیر',
        'rial': 'ریال',
        'method': 'روش برداشت وجه',
        'toAnotherYekpay': 'به حساب یک پی دیگر',
        'rialPayout': 'پرداخت ریالی',
        'toswift': 'انتقال حواله',
        'payoutenteramount': 'مبلغ مورد نظر برای برداشت وجه را وارد نمایید',
        'payoutselecbankacc': 'حساب بانکی خود را انتخاب نمایید',
        'personalgatewaytitle': 'درگاه پرداخت شخصی',
        'personalgatewaydesc': 'با درگاه شخصی یک پی می توانید تنها با ارایه QR و یا آدرس یکتا اختصاصی خود در یک پی آن را به مشتریان خود ارسال نموده و بصورت آنی و آنلاین وجه ارزی را دریافت کرده و به حساب بانکی خود انتقال دهید.',
        'accountsection': 'ناحیه کاربری',
        'addavatar': 'افزودن تصویر',
        'accountview': 'نمایش کاربری',
        'selectusername': 'نام کاربری خود را انتخاب کنید',
        'username': 'نام کاربری',
        'selectavatar': 'تصویر مورد نظر خود را انتخاب کنید',
        'uploadavatar': 'آپلود تصویر',
        'profile': 'پروفایل کاربری',
        'general': 'عمومی',
        'billinfo': 'اطلاعات صورتحساب',
        'coinfo': 'اطلاعات شرکت',
        'acclevel': 'سطح حساب',
        'uploadpassornatid': 'آپلود پاسپورت یا کارت شناسایی',
        'province': 'استان',
        'uploadlogo': 'لوگو',
        'uploadlatestcompanychange': 'آگهی آخرین تغییرات شرکت',
        'uploadnewspaper': 'تصویر روزنامه رسمی',
        'uploadnatidsign': 'کارت ملی یا شناسنامه صاحبان امضا',
        'uploadrentdoc': 'اجاره نامه یا سند مالکیت',
        'personal': 'شخصی',
        'free': 'رایگان',
        'business': 'کسب و کار',
        'monthly': 'ماهه',
        'annually': 'ساله',
        'packagedes': 'توضیحات پکیج',
        'premiumbusiness': 'کسب و کار ویژه',
        'buy': 'خرید',
        'sell': 'فروش',
        'settings': 'تنظیمات',
        'reports': 'رویداد ها',
        'yourcurrency': 'ارز مرجع شما',
        'autoexit': 'خروج خودکار',
        'zone': 'منطقه زمانی',
        'twostepverify': 'تایید دو مرحله ای',
        'autorenewalacc': 'تمدید خودکار سطح حساب',
        'actions': 'عملیات',
        'device': 'دستگاه',
        'ip': 'آی پی',
        'deactiveacc': 'غیر فعال کردن حساب',
        'currentpass': 'کلمه عبور فعلی',
        'newpass': 'کلمه عبور جدید',
        'repeatpass': 'تکرار کلمه عبور جدید',
        'smsnotify': 'اطلاع رسانی از طریق پیامک',
        'emailnotify': 'اطلاع رسانی از طریق ایمیل',
        'account': 'حساب کاربری',
        'notify': 'اطلاع رسانی',
        'sendNewTicket': 'ارسال تیکت جدید',
        'sendTicketResponse': 'پاسخ به تیکت',
        'sendTicketEmail': 'پاسخ تیکت شما به ایمیل شما ارسال خواهد شد.',
        'websitename': 'نام وب‌سایت',
        'websitetype': 'نوع وب‌سایت',
        'category': 'مجموعه',
        'subCategory': 'زیر مجموعه',
        'messageContent': 'متن پیام',
        'uploadbill': 'آپلود قبض',
        'you': 'شما',
        'supp': 'پشتیبانی',
        'downloadFile': 'دانلود فایل',
        'uploadfile': 'آپلود فایل',
        'ticketstitle': 'تیکت ها',
        'ticketsDescription': 'تیکت، یکی از راه های تضمین شده و بدون تاخیر مشتریان با دپارتمان های مختلف و یکی از پرکاربرد ترین و ساده ترین راه های ارتباطی با یک پی می باشد. ما را در این راه ارتباطی همراهی نمایید.',
        'ticketsList': 'لیست تیکت ها',
        'title': 'عنوان',
        'lastUpdate': 'آخرین بروزرسانی',

        'exchange': 'تبادل ارز',
        'walletpayout': 'برداشت از کیف پول',
        'customized': 'سفارشی',
        'lastMonth': 'ماه گذشته',
        'last6months': '۶ ماه گذشته',
        'lastYear': 'سال گذشته',
        'lastWeek': 'هفته قبل',
        'websitegatewaytitle': 'درخواست درگاه',
        'websitegatewaydesc': 'اگر جزو افرادی هستید که می‌خواهید کسب‌ و کار خود را خارج از مرزها گسترش دهید، یک پی برای اولین بار با ارائه درگاه پرداخت ارزی به وب سایت های ایرانی و فراهم کردن امکان پرداخت وجه توسط مشتری ها با ارزهای مختلف، فروش یا صادرات خدمات و محصولات به سرتاسر دنیا را تسهیل کرده است.',
        'addwebsitegateway': 'افزودن درگاه',
        'website': 'وب‌سایت',
        'field': 'تخصص',
        'gatewayinfo': 'اطلاعات درگاه',
        'weburl': 'آدرس وب‌سایت',
        'currentunit': 'واحد فعلی',
        'programminglanguage': 'زبان برنامه نویسی',
        'cms': 'سیستم مدیریت محتوا',
        'staticip': 'آی پی ثابت',
        'orgemail': 'ایمیل سازمانی',
        'uploadbranreg': 'آپلود گواهی ثبت برند',
        'successfulydone': 'با موفقیت ثبت شد',
        'yekpaylinktitle': 'یک پی لینک',
        'yekpaylinkdesc': 'یک پی لینک، یکی از ساده ترین و محبوب ترین روش های دریافت وجه می باشد. به محض تکمیل فرم ساخت یک پی لینک، یک لینک اینترنتی و یک QR Code مرتبط با آن به شما اختصاص می یابد. پس از آن می توانید این لینک و یا QR Code را برای مشتریان خود ارسال نمایید تا در کمترین زمان ممکن، وجه ریالی و یا ارزی مورد نظر را در بستر امن بانکی به شما پرداخت نمایند.',
        'myyekpaylinks': 'یک پی لینک های من',
        'addyekpaylink': 'اضافه کردن یک پی لینک',
        'link': 'لینک',
        'qrcode': 'کد QR',
        'validate': 'تاریخ اعتبار',
        'sendcapability': 'قابلیت ارسال',
        'selectyouravatar': 'تصویر آوتار خود را انتخاب کنید',
        'yekstocktitle': 'انتقال به بورس',
        'yekstockdeesc': 'شما میتوانید موجودی کیف پول خود را به حساب بانکی خود در داخل کشور توسط انتقال پایا و یا از طریق ارسال حواله به حساب های خراج از کشور واریز نمایید. کارمزد خدمات یک پی به شرح زیر است',
        'connecttostock': 'اتصال به بورس',
        'stockcode': 'کد بورسی',
        'stockname': 'نام کارگزار',
        'bankaccnum': 'شماره حساب',
        'expirationdate': 'تاریخ انقضا',
        'acceptterms': 'قوانین و مقررات را میپذیرم',
        'send': 'ثبت',
        'addyourwallet': 'کیف پول خود را بسازید',
        'addYourBankAccount': 'اکانت بانکی خود را بسازید',
        'addyourpersonalgateway': 'درگاه شخصی خود را بسازید',
        'transactionsList': 'لیست تراکنش ها',
        'websiteGateway': 'درگاه پرداخت وبسایت',
        'yekpayLink': 'یک پی لینک',
        'moneyexchange': 'انتقال وجه',
        'POS': 'کارتخوان',
        'yekstock': 'یک استاک',
        'personalGateway': 'درگاه پرداخت شخصی',

        'sendmoneytitle': 'انتقال وجه',
        'sendmoneydesc': 'درگاه پرداخت شخصی، یکی از ساده ترین و محبوب ترین روش های دریافت وجه می باشد. به محض تکمیل فرم ساخت درگاه، یک لینک یکتا و یک QR Code مرتبط با آن به شما اختصاص می یابد. پس از آن می توانید این لینک و یا QR Code را برای مشتریان خود ارسال نمایید تا در کمترین زمان ممکن، وجه ریالی و یا ارزی مورد نظر را در بستر امن بانکی به شما پرداخت نمایند.',
        'transfertitle': 'پرداخت حواله',
        'transferdesc': 'شما میتوانید کیف پول خود را شارژ کنید.',
        'transfer': 'حواله',
        'currencyunit': 'واحد ارزی',
        'details': 'توضیحات',
        'transactions': 'تراکنش ها',
        'withdraw': 'برداشت',
        'receive': 'دریافت',
        'referenceno': 'شماره مرجع',
        'type': 'نوع',
        'previous': 'قبلی',
        'next': 'بعدی',
        'noDataFound': 'شما تاکنون تراکنشی انجام نداده اید',
        'page': 'صفحه',
        'event': 'رویداد',
        'of': 'از',
        'rows': 'سطر',
        'pageJump': 'پریدن به صفحه',
        'rowsSelector': 'سطر در صفحه',
        'to': 'به',
        'for': 'برای',
        'cancel': 'انصراف',
        'payer': 'پرداخت کننده',
        'confirmmobnum': 'تایید شماره همراه',
        'export': 'خروجی',
        'exchangeRate': 'نرخ ارز',
        'select2': 'انتخاب',
        'sendmoney': 'ارسال مبلغ به',
        'paymentlinkdesc': 'جهت درخواست درگاه ارزی و یا ریالی برای وب سایت خود، می بایست فرم مورد نظر را تکمیل نمایید. سپس همکاران بخش درگاه',
        'paymentlinkruleslink': 'لینک',
        'pay': 'پرداخت',
        'changePass': 'تغییر رمز عبور',
        'done': 'با موفقیت انجام شد',
        'forgotPassConfirmCodeWrong': 'اشتباه است',
        'info': 'مشخصات',
        'verifylogin': 'کد تایید',
        'persian': 'فارسی',
        'trackingCode': 'کد رهگیری',
        'repeatUsername': 'نام کاربری قبلا استفاده شده است',
        'errorNotFound': 'صفحه مورد نظر شما پیدا نشد!',
        'approved': 'تائید شده',

        'planUpgraded': 'طرح شما با موفقیت آپگرید شد',
        'failed': 'شکست خورد',
        'lowBalance': 'موجودی حساب شما کم است. لطفا شارژ کنید',
        'errorTitle': 'مشکلی پیش آمد!',
        'errorDescription': 'لطفاً دوباره تلاش نمایید',
        'commission': 'مبلغ کمیسیون: ',
        'deleteAccount': 'حذف حساب کاربری',
        'deleteText': 'در صورتی که اکانت کاربری خود را حذف کنید، بر اساس قوانین و مقررات یک پی، اطلاعات مربوط به آن تا 5 سال نزد یک پی باقی خواهد ماند. همچنین پس از حذف اکانت خود، دیگر قادر به بازگرداندن آن نخواهید بود.',
        'at_now_just_try_payment_link': 'در حال حاضر پرداخت با ارز لیر و یورو میسر می باشد.',
        'enter_credit_card_info': 'اطلاعات کارت خود را وارد نمایید',
        'card_number': 'شماره کارت',
        'name_on_card': 'نام',
        'expire_date': 'تاریخ انقضا (سال/ماه)',
        'cvc': 'رمز کارت'

    },
    en: {
        "turkishID":"Turkish Identity Number",
        'yes': 'Yes',
        'deleteSure': 'Are you sure to delete this item?',
        'changeSure': 'Are you sure to change this item status?',
        'fillBillingForm': 'Please fill the form',
        'refundReason': 'Refund Reason',
        'refunded': 'Refunded',
        'yourActivePlan': 'Your Active Plan',
        'choose': 'Select',
        'uploadProfilePicture': 'Upload Profile Picture',
        'verifyMobile': 'Verify Mobile',
        'active': 'Active',
        'inactive': 'Inactive',
        'enter5DigitNumber': 'Enter 5 digit number that has been sent to your mobile number',
        'optional': 'Optional',
        'businessPlus': 'Business Plus',
        'merchantCode': 'Merchant Code',
        'trial': 'Trial',
        'availableAmount': 'Available amount',
        'yourResponse': 'Your Answer',
        'supportResponse': 'Support Answer',
        'logout': 'Logout',
        'pleaseChooseYourUsername': 'Please enter your personal payment gateway username',
        'editGateway': 'Edit Gateway',
        'pleaseEnterGateWayInformation': 'Please enter your website information',
        'pleaseEnterGateWayTechnicalInformation': 'Please enter your website technical information',
        'pleaseEnterGateWayContactInformation': 'Please enter your website contact information',
        'individual': 'Individual',
        'corporate': 'Corporate',
        'uploadLogo': 'Upload Logo',
        'bankAccountReceiptTooltip': 'This field is not required for Iranian Rial bank accounts. Although for international bank accounts there is need to upload a picture of the bank receipt containing the account holder\'s name and IBAN number.',
        'pleaseEnterYekpayLinkInformation': 'Please enter your Yekpay link information',
        'pleaseSelectYekpayLinkImage': 'Please select Yekpay link image',
        'uploadImage': 'Upload Image',
        'firstNameCanNotChange': 'The first name entered can not be changed after registration.',
        'lastNameCanNotChange': 'The last name entered can not be changed after registration.',
        'emailCanNotChange': 'The email entered can not be changed after registration.',
        'promotionCodeTooltip': 'Enter your promotion code if you have.',
        'bankAccountImageValidation': '(Max: 2Mg, jpg,png)',
        'Upload': 'Upload',
        'passportUploadTooltip': 'Please provide a photo of you, holding your passport alongside your face. Make sure your face be visible and passport details are clearly readable.',
        'passport/identity': 'Passport/Identity Card',
        'profilePicture': 'Profile Picture',
        'yekpayLinkDescription': 'Services/Goods Description',
        'yekpayLinkDescriptionPlaceholder': 'Please describe in details which services or goods you want to sell.',
        'yekpayLinkDPayerescriptionPlaceholder': 'Please describe in details which services or goods you have purchased from the vendor',
        'name': 'Name',
        'euroLimitation': 'Min: 5 EUR - Max : 2500 EUR',
        'tryLimitation': 'Min: 10 TRY - Max : 15000 TRY',
        'gbpLimitation': 'Min: 5 GBP - Max : 2000 GBP',
        'aedLimitation': 'Min: 10 AED - Max : 10000 AED',
        'yekpay_link_form_title': 'Please fill out the form below carefully in English. Otherwise your transaction may be canceled.',


        'yourRequestRegistered': 'Your request has been done successfully.',
        'submit': 'Submit',

        'successful': 'Successful',
        'unSuccessful': 'Unsuccessful',
        'ok': 'OK',
        'fullName': 'Full Name',
        'description': 'Description',

        'email': 'Email',
        'password': 'Password',
        'login': 'Login',
        'doNotHaveAccount': 'Do not have account?',
        'createAccount': 'Create Account',
        'forgotPasswordQuestion': 'Forgot Password?',
        'loginDescription': 'Yekpay is the first international online payment platform, aims to facilitate international transactions for individuals and businesses with safe, fast and fair-cost services on the secure banking platform.',

        'forgotPassword': 'Forgot Password',
        'forgotPasswordEmailSent': 'Verification code has been sent to your email address.',
        'confirmationCode': 'Verification Code',
        'confirmationCodeAccepted': 'Your verification code accepted. Now you can set your new password.',
        'passwordChangedSuccessfully': 'Your password has been changed successfully',

        'register': 'Register',
        'firstName': 'First Name',
        'lastName': 'Last Name',
        'promotionCode': 'Promotion Code',
        'haveAccount': 'Already have account?',
        'mobile': 'Mobile',
        'phone': 'Phone',


        'emailActivation': 'Email Activation',
        'emailVerificationCodeSent': 'Verification code has been sent to your email address',
        'emailVerificationDescription': '5 Digit verification code has been sent to your email address, Please paste it to below field',
        'sendEmailVerificationAgain': 'Click here if you did not get email',
        'doneSuccessfully': 'Done Successfully',


        'topup': 'Top-Up',
        'chargeWalletTitle': 'Top-Up Wallet',
        'chargeWalletDescription': 'You can top up money to your IRR or other wallets either with your SHETAB card or international debit cards instantly or via banking wire transfer (depending on deposit time).',
        'selectChargeWalletMethod': 'Choose your method for charging your wallet.',
        'shetabCards': 'Shetab Cards(IRR)',
        'wire': 'Wire Transfer',
        'creditCards': 'Debit Cards',
        'irrTopupDescription': 'You can top up your IRR wallet with shetab cards online. Your requested amount must be between 10,000 IRR and 500,000,000 IRR. Your wallet will be charge in 3 hours after successful payment. Transactions that have been paid by Turkish citizens on Turkish Liras currency, 18% Value Added Tax (KDV) will be charged',
        'irrTopupTitle': 'Top-Up - Shetab Cards(IRR)',
        'wireTopupTitle': 'Top-Up Wallet - Wire Transfer',
        'wireTopupDescription': 'You can top up your wallet via banking wire transfer. For this purpose fill the form according payer information. We will check your request until 24 hours and send you our bank account details. Your wallet will be charged after your successful wire transfer',
        'creditTopupTitle': 'Top-Up Wallet - Debit Cards',
        'creditTopupDescription': 'You can top up your wallet with international debit cards that have 3DSecure. Your wallet will be charge in 3 hours after successful payment.',

        'newWallet': 'New Wallet',


        'walletCharge': 'Top-Up Wallet',

        'transactionReceipt': 'Transaction Receipt',
        'transactionTime': 'Transaction Time',
        'transactionStatus': 'Transaction Status',


        'payout': 'Payout',
        'payoutDescription': 'You can payout your wallet balance to your Iranian bank accounts via PAYA transfer or by wire transfer to your international bank accounts.',
        'selectPayoutMethod': 'Choose your method for payout',
        'payoutIRRTitle': 'Payout - IRR Payout',
        'payoutIRRDescription': 'You can payout your Iranian Rial wallet balance to the bank account you have defined. This is possible on Iranian banks working days (saturday - wednesday) from 8 am to 3:30 pm. Note that the sum of the requested amount and the commission should not exceed your Rial wallet balance.',
        'payoutTransferTitle': 'Payout - Transfer to another yekpay account',
        'payoutTransferDescription': 'You can transfer wallet balance to another Yekpay account instantly without any commission. Note that your destination Yekpay account must have this wallet.',
        'payoutWireTitle': 'Payout - Wire Transfer',
        'payoutWireDescription': 'You can payout your wallet balance to your bank accounts with wire transfer. For this purpose, please fill the below form. ',
        'IRRPayout': 'IRR Payout',


        'bankAccount': 'Bank Account',
        'holderName': 'Account holder name',


        'companyName': 'Company Name',

        'download': 'Download',
        'buyPlugin': 'Buy Plugin',


        'exchangeTitle': 'Exchange',
        'exchangeDescription': 'To exchange your fund currency, please select the source wallet, destination and amount requested for an exchange. Note that any currency exchange includes 500 Rials commission in IRR currency originating and in other currency exchanges 0.30 unit of origin currency will be deducted for each exchange operation.',
        'fromCurrency': 'From Currency',
        'toCurrency': 'To Currency',


        'yekpayLinkName': 'Yekpay Link Name',
        'operations': 'Operations',


        'toAnotherYekpay': 'To another Yekpay account',
        'wireTransfer': 'Wire Transfer',


        'yekpay': 'Yekpay',
        'wallet': 'Wallet',
        'wrongPassword': 'نام کاربری و کلمه عبور همخوانی ندارند.',
        'reason': 'Reason',
        'refundTitle': 'Refund',
        'refund': 'Refund',
        'emailVerificationHasSent': 'Verification code has been sent to your email address',
        'plsVerifyEMailAgain': 'Send verification code again',
        'verify': 'Verify',
        'plsVerifyEMailDesc': '5 Digit verification code has been sent to your email address, Please paste it to below field',
        'plsVerifyEMail': 'Email Address Verification',
        'email_has_taken': 'This email address has been taken.',
        'yekpaydashboard': 'Yekpay Dashboard',
        'tickets': 'Tickets',
        'messages': 'Messages',
        'exit': 'Sign Out',
        'dollar': 'Dollar',
        'euro': 'Euro',
        'copyright': "Copyright \u00A9 Yekpay. All Rights Reserved.",
        'chooseimage': 'Choose image',
        'edit': 'Edit',
        'remove': 'Remove',
        'loading': 'Loading',
        'addbankacc': 'Add Bank Account',
        'shabanum': 'IBAN',
        'swiftnum': 'Swift code',
        'swiftplaceh': 'Swift code is not necessary for iranian bank accounts.',
        'accholdername': 'Account holder name',
        'nameandfamilyname': 'Full Name',
        'bankandbranch': 'Bank and branch name',
        'bankandbranchplaceh': 'HSBC',
        'recieptstatusupload': 'Please upload your bank account receipt',
        'uploadreciept': 'Upload Receipt',
        'add': 'Add',
        'mybankaccounts': 'My Bank Account',
        'addbankaccounts': 'Add Bank Account',
        'start': 'Start',
        'end': 'End',
        'renew': 'Renew',
        'bankaccounttitle': 'Bank Account',
        'bankaccountdesc': 'You need add bank account for payout your wallets balance. We will check your bank account information until 24 hours.',
        'bankaccountlist': 'Bank Accounts List',
        'personname': 'Name',
        'status': 'Status',
        'createddate': 'Created Date',
        'documents': 'Documents',
        'codesandissues': 'Sample Codes',
        'developerstitle': 'Developer Guides',
        'developersdesc': 'You can buy and download Yekpay payment plugins if you use any CMS or use API documents for integration your website to Yekpay payment gateway',
        'plugins': 'Plugins',
        'codes': 'Documents',
        'pluginshop': 'Plugin Shop',
        'woocommerce': 'Woocommerce',
        'version': 'Version',
        'language': 'Language',
        'currency': 'Currency',
        'exchangetitle': 'Exchange',
        'exchangedesc': 'جهت تبادل ارز لطفا کیف پول مبدا، مقصد و مبلغ درخواستی جهت تبادل را انتخاب نمایید. توجه داشته باشید هر گونه تبادل ارز شامل 500 ریال کارمزد در تبادل های با ارز مبدا ریال و 30 سنت در تبادل های با ارز مبدا غیر از ریال می گردد.',
        'selectwallet': 'Select Wallet',
        'confirm': 'OK',
        'billreciept': 'Transaction Receipt',
        'continue': 'Continue',
        'back': 'Previous',
        'complete': 'Complete',
        'payoutfrom': 'Payout From',
        'amount': 'Amount',
        'value': 'Amount',
        'converto': 'Convert To',
        'select': 'Select',
        'confirmexchange': 'Exchange Confirmation',
        'date': 'Date',
        'save': 'Save',
        'print': 'Print',
        'share': 'Share',
        'mastercardtitle': 'Debit Cards',
        'mastercarddesc': 'برای تبادل ارز لطفا کیف پول مبدا و مقصد خود را انتخاب بفرمایید توجه داشته باشید هرگونه تبادل ارز شامل مالیات خواهد شد. برای تبادل ارز لطفا کیف پول مبدا و مقصد خود را انتخاب بفرمایید توجه داشته باشید هرگونه تبادل ارز شامل مالیات خواهد شد.',
        'mymastercards': 'کارت های من',
        'addmastercard': 'اضافه کردن کارت',
        'balance': 'Balance',
        'hour': 'Hour',
        'mastercardname': 'نام کارت',
        'mastercardwiztittle': 'قبل از پرداخت میبایست فرم زیر را بر اساس اطلاعات پرداخت کننده تکمیل کنید',
        'birthdate': 'تاریخ تولد',
        'familyname': 'نام خانوادگی',
        'country': 'Country',
        'city': 'City',
        'address': 'Address',
        'postalcode': 'Postal Code',
        'nationality': 'Nationality',
        'nationalcode': 'National ID',
        'passnum': 'Passport Number',
        'telephonenum': 'Phone Number',
        'mobilenum': 'Mobile',
        'uploadpass': 'Upload Passport',
        'uploaddoc': 'Upload Document',
        'mastercardreciept': 'رسید صدور کارت بین المللی',
        'paywith': 'Pay With Wallet',
        'personalinfo': 'Personal Information',
        'locationinfo': 'Location Information',
        'contactinfo': 'Contact Information',
        'docs': 'Documents',
        'reciept': 'Receipt',
        'issueDebitCard': 'Issue Debit Card',
        'mywallettitle': 'Create Wallet',
        'mywalletdesc': 'You can build your own wallet to have transaction depends on your account plan. Note that you can not change or delete wallets that you had any successful or unsuccessful transaction on it.',
        'yoanwallet': 'CNY Wallet',
        'rialwallet': 'IRR Wallet',
        'eurowallet': 'EUR Wallet',
        'dollarwallet': 'USD Wallet',
        'chooseYourWallet': 'Choose Your Wallet',
        'lirwallet': 'TRY Wallet',
        'addwallet': 'Add New Wallet',
        'payouttitle': 'Payout',
        'rial': 'IRR',
        'method': 'Method',
        'bankaccount': 'Bank Account',
        'selectchargemethod': 'روش خود جهت شارژ را انتخاب نمایید',
        'rialPayout': 'IRR Payout',
        'toswift': 'Wire Transfer',
        'payoutenteramount': 'مبلغ مورد نظر برای برداشت وجه را وارد نمایید',
        'payoutselecbankacc': 'Choose your bank account',
        'personalgatewaytitle': 'Personal Payment Gateway',
        'personalgatewaydesc': 'You can get money instantly from your customers just via a QR Code or unique URL and send it to your bank account.',
        'accountsection': 'Profile',
        'addavatar': 'Add Picture',
        'accountview': 'Account View',
        'selectusername': 'Choose your username',
        'username': 'Username',
        'selectavatar': 'Select your picture',
        'uploadavatar': 'Upload Pictre',
        'profile': 'Profile',
        'general': 'General',
        'billinfo': 'Biling Information',
        'coinfo': 'Company Information',
        'acclevel': 'Account Plan',
        'uploadpassornatid': 'Upload passport/Identity',
        'province': 'Province',
        'companyname': 'Cpmpany name',
        'uploadlogo': 'Logo',
        'uploadlatestcompanychange': 'Last Company Changes',
        'uploadnewspaper': 'Official Newspaper',
        'uploadnatidsign': 'Owner Passport/Identity',
        'uploadrentdoc': 'Ownership Document',
        'personal': 'Personal',
        'free': 'Free',
        'business': 'Business',
        'monthly': 'Monthly',
        'annually': 'Annually',
        'packagedes': 'Package Description',
        'premiumbusiness': 'Business Plus',
        'buy': 'Buy',
        'sell': 'Sell',
        'settings': 'Settings',
        'reports': 'Reports',
        'yourcurrency': 'Reference Currency',
        'autoexit': 'Auto Sign Out',
        'zone': 'Time/Zone',
        'twostepverify': 'Two Step Verification',
        'autorenewalacc': 'Auto Renew Plan',
        'actions': 'Actions',
        'device': 'Device',
        'ip': 'IP',
        'deactiveacc': 'Delete Account',
        'currentpass': 'Current Password',
        'newpass': 'New Password',
        'repeatpass': 'Confirm New Password',
        'smsnotify': 'Notification via SMS',
        'emailnotify': 'Notification via Email',
        'account': 'Account',
        'notify': 'Notifications',
        'sendNewTicket': 'Send New Ticket',
        'sendTicketResponse': 'Send Ticket Response',
        'sendTicketEmail': 'Ticket response will be send to your email address.',
        'websitename': 'Website Name',
        'websitetype': 'Website Type',
        'category': 'Category',
        'subCategory': 'Sub category',
        'messageContent': 'Message Content',
        'uploadbill': 'Upload Bill',
        'you': 'You',
        'supp': 'Support',
        'downloadFile': 'Download File',
        'uploadfile': 'Upload Attachments',
        'ticketstitle': 'Tickets',
        'ticketsDescription': 'Ticketing is one of the most guaranteed, useful and easiest ways for you to communicate with all Yekpay departments at the earliest possible time. Please keep in touch with us in this way.',
        'ticketsList': 'Tickets List',
        'title': 'Title',
        'lastUpdate': 'Last Update',

        'exchange': 'Exchange',
        'walletpayout': 'Payout',
        'customized': 'Customized',
        'lastMonth': 'Last Month',
        'last6months': 'Last 6 Month',
        'lastYear': 'Last Year',
        'lastWeek': 'Last Week',
        'websitegatewaytitle': 'Request Website Gateway',
        'websitegatewaydesc': 'Yekpay Payment Gateway is the best way if you want expand your business around the world. ',
        'addwebsitegateway': 'Add Gateway',
        'website': 'Website',
        'field': 'Filed',
        'gatewayinfo': 'Gateway Information',
        'weburl': 'Website URL',
        'currentunit': 'Current Currency',
        'programminglanguage': 'Programming Language',
        'cms': 'CMS',
        'staticip': 'Static IP',
        'orgemail': 'Organization Email',
        'uploadbranreg': 'Logo Registration',
        'successfulydone': 'Registered Successfully',
        'yekpaylinktitle': 'Yekpay Link',
        'yekpaylinkdesc': 'You can get money instantly from your customers just via a QR Code or unique URL and send it to your bank account.',
        'myyekpaylinks': 'My Yekpay Links',
        'addyekpaylink': 'Add Yekpay Link',
        'link': 'Link',
        'qrcode': 'QR Code',
        'validate': 'Validate',
        'sendcapability': 'Delivery',
        'selectyouravatar': 'Choose Your Avatar',
        'yekstocktitle': 'YekStock',
        'yekstockdeesc': 'شما میتوانید موجودی کیف پول خود را به حساب بانکی خود در داخل کشور توسط انتقال پایا و یا از طریق ارسال حواله به حساب های خراج از کشور واریز نمایید. کارمزد خدمات یک پی به شرح زیر است',
        'connecttostock': 'Connect to stock',
        'stockcode': 'Stock Code',
        'stockname': 'Brokcer Name',
        'bankaccnum': 'Account Number',
        'expirationdate': 'Expire Date',
        'acceptterms': 'I agree term and conditions.',
        'send': 'Send',
        'addyourwallet': 'Create your own wallet',
        'addYourBankAccount': 'Add your bank account',
        'addyourpersonalgateway': 'Create your personal payment gateway',
        'transactionsList': 'Transactions List',
        'websiteGateway': 'Website Payment Gateway',
        'yekpayLink': 'Yekpay Link',
        'moneyexchange': 'Transfer Money',
        'POS': 'V-POS',
        'yekstock': 'Yek Stock',
        'personalGateway': 'Personal Payment Gateway',

        'sendmoneytitle': 'Transfer Money',
        'sendmoneydesc': 'درگاه پرداخت شخصی، یکی از ساده ترین و محبوب ترین روش های دریافت وجه می باشد. به محض تکمیل فرم ساخت درگاه، یک لینک یکتا و یک QR Code مرتبط با آن به شما اختصاص می یابد. پس از آن می توانید این لینک و یا QR Code را برای مشتریان خود ارسال نمایید تا در کمترین زمان ممکن، وجه ریالی و یا ارزی مورد نظر را در بستر امن بانکی به شما پرداخت نمایند.',
        'chargewallettitle': 'شارژ کیف پول',
        'chargewalletdesc': 'شما می توانید کیف پول های ریالی و ارزی خود را با کارت شتابی یا کارت های بین المللی به صورت آنی و یا به وسیله حوالی بانکی (بسته به زمان واریز) شارژ نمایید.',
        'chargewalletamount': 'مبلغ مورد نظر برای شارژ کیف پول.',
        'transfertitle': 'پرداخت حواله',
        'transferdesc': 'شما میتوانید کیف پول خود را شارژ کنید.',
        'shetabcard': 'Shetab Card(IRR)',
        'transfer': 'Wire Transfer',
        'othercards': 'کارت‌های ارزی',
        'currencyunit': 'Currency',
        'details': 'Details',
        'transactions': 'Transactions',
        'withdraw': 'Payout',
        'receive': 'Receive',
        'referenceno': 'Reference Number',
        'type': 'Type',
        'previous': 'Previous',
        'next': 'Next',
        'noDataFound': 'No data found',
        'page': 'Page',
        'event': 'Event',
        'of': 'Of',
        'rows': 'Rows',
        'pageJump': 'Go to page',
        'rowsSelector': 'Rows Per Page',
        'confirmcode': 'Verification Code',
        'to': 'To',
        'for': 'For',
        'cancel': 'Cancel',
        'payer': 'Payer',
        'confirmmobnum': 'Mobile Verification',
        'export': 'Export',
        'exchangeRate': 'Exchange Rate',
        'select2': 'Select',
        'sendmoney': 'Send Money To',
        'paymentlinkdesc': 'جهت درخواست درگاه ارزی و یا ریالی برای وب سایت خود، می بایست فرم مورد نظر را تکمیل نمایید. سپس همکاران بخش درگاه',
        'paymentlinkruleslink': 'لینک',
        'pay': 'Pay',
        'changePass': 'Change Password',
        'done': 'Done',
        'forgotPassConfirmCode': 'Verificaiton code has been sent to your email adress.',
        'forgotPassConfirmCodeWrong': 'Is Not Valid',
        'info': 'Information',
        'verifylogin': 'Verification Code',
        'persian': 'Persian',
        'trackingCode': 'Tracking code',
        'repeatUsername': 'Username already taken.',
        'errorNotFound': 'Page Not Found!',
        'approved': 'Approved',
        'pending': 'Pending',
        'suspend': 'Suspend',
        'declined': 'Declined',
        'planUpgraded': 'Your plan upgraded successfully',
        'failed': 'Failed',
        'lowBalance': 'Your wallet balance is low.',
        'errorTitle': 'Oops!',
        'errorDescription': 'Please Try Again',
        'commission': 'Commission',
        'deleteAccount': 'Delete Account',
        'deleteText': 'By deleting your Yekpay account, your account information will remain on Yekpay database for 5 years, according to Yekpay privacy policy. Also, note that deleted accounts can not be restored.',
        'at_now_just_try_payment_link': 'At now You can just pay in TRY and EUR currencies.',
        'enter_credit_card_info': 'Credit Card Information',
        'card_number': 'Card Number',
        'name_on_card': 'Name',
        'expire_date': 'Expire Date (MM/YY)',
        'cvc': 'CVC'
    }
};

export default languages;

import React, {Component} from 'react';
import { connect } from 'react-redux';
import {hideModal, showModal} from '../actions';
import PropTypes from "prop-types";
import ReactBSAlert from "react-bootstrap-sweetalert";
import languages from "../languages";
import {Input, InputGroup, InputGroupAddon, InputGroupText, Row} from "reactstrap";
import Loading from "../views/components/Loading";
import RequestServer from "../RequestServer";
import {validateNumber, ValidationErrors} from "../Values";
import classnames from "classnames";
import errorsStrings from "../errorsStrings";

class ConfirmMobileDialog extends Component {
    constructor(props){
        super(props);
        this.state = {
            token: '', tokenErr: '',
            loading: true,
        };
    }

    componentDidMount() {
        this.makeRemoteRequestMobile();
    }

    makeRemoteRequestMobile = () => {
        const { mobile, userData } = this.props;
        this.setState({ loading: true });
        RequestServer('post', 'user/mobile-token', () => {
            this.setState({ loading: false });
        }, {
            token: userData.token,
            post: { mobile },
            onAnyFailed: () => this.setState({ loading: false })
        });
    };

    change = (event, stateName) => {
        const {lang} = this.props;

        const value = event.target.value;
        let err = '';
        if (stateName === "token") {
            err = validateNumber(value) && value.trim().length ===  5 ? "ss" : errorsStrings[lang].inputIsNotValid;
        }
        this.setState({ [stateName]: event.target.value, [stateName + "Err"]: err });
    };

    getClass = (stateName) => {
        const err = this.state[stateName + "Err"];
        let classErr = 'has-danger';
        if (err === "ss")
            classErr = 'has-success';
        else if (err === '')
            classErr = '';
        return classnames(classErr, { "input-group-focus mx-auto": this.state.focus === stateName })
    };

    renderError = (stateName) => {
        const err = this.state[stateName + "Err"];
        if (err !== "" && err !== "ss")
            return (<label className="error">{err}</label>)
    };

    render() {
        const {lang, hideModal} = this.props;
        const { loading, token } = this.state;
        console.log(lang)

        return (
            <ReactBSAlert
                title={languages[lang].verifyMobile}
                style={{ display: "block", marginTop: "-200px" }}
                onConfirm={this.makeRemoteRequestVerify}
                onCancel={hideModal}
                confirmBtnBsStyle={'success'}
                confirmBtnText={languages[lang].confirm}
                cancelBtnText={languages[lang].cancel}
            >
                <h3 className="mb-0 primary-color">{languages[lang].enter5DigitNumber}</h3>
                <Row className="mt-4 col-md-12">
                    {loading ? (<Loading lang={lang} />) : (
                        <InputGroup className={this.getClass('token')}>
                            <InputGroupAddon addonType="prepend" className="col-md-12 px-0 mx-auto">
                                <InputGroupText md="2">
                                    <i className="fal fa-comment-alt-check" />
                                </InputGroupText>
                                <Input
                                    type="text"
                                    value={token}
                                    onChange={e => this.change(e, 'token')}
                                    onFocus={() => this.setState({ focus: 'token' })}
                                    onBlur={() => this.setState({ focus: null })} />
                            </InputGroupAddon>
                            {this.renderError('token')}
                        </InputGroup>
                    )}
                </Row>
            </ReactBSAlert>
        );
    }

    makeRemoteRequestVerify = () => {
        const {lang, showModal, userData} = this.props;
        const { token, tokenErr, loading } = this.state;
        if (!loading) {
            if (tokenErr === 'ss') {
                this.setState({ loading: true });
                RequestServer('post', 'user/mobile-verify', () => {
                    showModal('QuestionDialog', {
                        title: languages[lang].verifyMobile,
                        description: languages[lang].doneSuccessfully,
                        hideNoBtn: true
                    });
                }, {
                    token: userData.token,
                    post: { token },
                    onAnyFailed: () => this.setState({ loading: false }),
                    onError: (error) => {
                            this.setState({ focus: 'token', tokenErr: languages[lang].forgotPassConfirmCodeWrong });
                    },
                });
            } else {
                if (tokenErr === '')
                    this.setState({ focus: 'token', tokenErr: errorsStrings[lang].inputRequired });
            }
        }
    };
}

ConfirmMobileDialog.propTypes = {
    mobile: PropTypes.string.isRequired,
    lang: PropTypes.string,
};

ConfirmMobileDialog.defaultProps = {
    lang: 'en'
};

const mapStateToProps = (state) => {
    return {
        userData: state.userReducer
    }
};

export default connect(mapStateToProps, { hideModal, showModal })(ConfirmMobileDialog);
